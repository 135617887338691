import React, { useRef, useEffect } from 'react';
import WaterProofingVideo1 from '../assets/Waterproofing/wt2.mp4'; // Video file for the slide

function WaterProofing() {
    const commonClass = "w-full h-[610px] object-cover rounded-lg shadow-md"; // Increased height to 700px
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('click', (e) => {
                e.preventDefault(); // Prevent video from going fullscreen
            });
        }
    }, []);

    return (
        <div className="max-w-4xl mx-auto p-4">
            <div>
                <video
                    ref={videoRef}
                    autoPlay
                    loop
                    muted
                    playsInline
                    className={commonClass}
                >
                    <source src={WaterProofingVideo1} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
}

export default WaterProofing;
