import {
  IconButton,
  MobileNav,
  Navbar,
  Typography
} from "@material-tailwind/react";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Logo from "../assets/Navbar/Logo.png";

const Header = () => {
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <li className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-2 lg:flex-row lg:items-center lg:gap-20 xxl:gap-28">
      <Helmet>
        <title>Contact Us for Professional Pest Control Services | Get a Free Quote</title>
        <meta name="description" content="Contact us today for a free quote on our professional pest control services. Our experts are ready to assist with bed bug treatment, termite control, rodent removal, and more. Reach out to us now for affordable pest solutions." />
        <meta name="keywords" content="contact pest control, pest control quote, pest control services, pest control experts, free pest control quote, pest control contact, bed bug treatment, termite control, rodent removal, pest control consultation, pest control inquiry, schedule pest control, pest control near me, pest solutions, local pest control, pest control assistance, pest control help, contact exterminator, pest management, pest control appointment, affordable pest control, professional pest control, pest control advice, best pest control, pest control service near me, pest control companies, exterminator contact, pest control information, pest control customer service, pest control support, pest control service request, pest control free estimate, termite treatment, mosquito control, ant exterminator, roach killer, pest control pricing, pest control customer care, emergency pest control, pest control hotline, pest control team, pest control specialists, pest removal services, pest control experts near me, pest control inspection, contact exterminator near me, reliable pest control, pest control contact number, pest control email, pest control service number, pest control hotline number, pest control phone number, pest control booking, pest control scheduling, pest control consultation services, pest control customer support, pest control company contact" />
      </Helmet>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <a
          href="tel:+918010281236"
          className="flex items-center text-lg pr-[8rem] xl:pr-[12rem] lg:pl-[2rem] xxl:pl-12 text-green-500 font-bold"
        >
          <CallIcon style={{ marginRight: "8px" }} />
          +918010281236
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal"
      >


        <a
          href="mailto:entomon.pestsolution@gmail.com"
          className="flex items-center mr-6 xl:ml-[-8.5rem] lg:-ml-32  xl:text-lg lg:text-lg text-black font-bold"
        >
          <EmailIcon style={{ marginRight: "8px" }} />
          entomon.pestsolution@gmail.com{" "}
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <a
          target="_blank"
          href="https://api.whatsapp.com/send?phone=918010281236"
          rel="noreferrer"
          className="flex items-center pl-9 xl:pl-2 xl:text-lg text-green-500 font-bold xsm:pl-1"
        >
          <WhatsAppIcon style={{ marginRight: "8px" }} />
          WhatsApp
        </a>
      </Typography>
    </li>
  );

  return (
    <Navbar
      className="mx-auto max-w-screen-xxl py-2 px-4 lg:px-8 lg:py-4"
      style={{ boxShadow: "-moz-initial" }}
    >
      <div className="mx-auto flex items-center justify-between text-blue-gray-900">
        <Typography
          as="a"
          href="/"
          className="mr-4 cursor-pointer py-1.5 font-medium"
        >
          <img
            className="mx-auto flex h-[5rem] w-auto max-w-screen-xl "
            src={Logo}
            alt="Pest Control In Pune"
          />
        </Typography>

        <div className="hidden lg:flex">{navList}</div>
        <Link
          to="/contact"
          variant="gradient"
          size="sm"
          className="hidden text-base lg:inline-block text-black font-bold ml-12 lg:mr-28 xxl:ml-32"
        >
          <span>Contact Us</span>
        </Link>
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6 text-black font-bold"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-black"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
      </div>
      <MobileNav open={openNav}>
        <div className="container mx-auto text-black m-5 gap-y-7">
          {navList}
          <Link
            to="/contact"
            variant="gradient"
            size="sm"
            className="mb-2 ml-2 text-black bg-white font-bold"
          >
            <span>Contact Us</span>
          </Link>
        </div>
      </MobileNav>
    </Navbar>
  );
};

export default Header;
